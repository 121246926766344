import React from 'react';
import { yon as y, define, defineColor } from 'yons';
import { insertGlobal } from 'glamor';
import Helmet from 'react-helmet';

import br from '../images/br.png';
import bl from '../images/bl.png';
import tl from '../images/tl.png';
import tr from '../images/tr.png';
import tl_flower from '../images/tl_flower.png';
import br_flower from '../images/br_flower.png';

import Intro from '../content/intro.mdx';
import Accom from '../content/accom.mdx';
import Taxis from '../content/taxis.mdx';
import Bridesmaids from '../content/bridesmaids.mdx';
import Groomsmen from '../content/groomsmen.mdx';

define('cursive', {
  fontFamily: 'annabelle-jf',
  fontWeight: 400,
  fontStyle: 'normal',
});
define('marker', {
  fontFamily: 'permanent-marker',
  fontWeight: 400,
  fontStyle: 'normal',
});
define('ts', {
  textShadow: '0 1px 0 rgba(255,255,255, 0.6)',
});
define('ts0', {
  textShadow: 'none',
});
define('f0', { fontSize: '2.8rem' });
defineColor('grey', '#5C5B60');
defineColor('goldDark', '#8c5700');
defineColor('gold', '#DEA848');
defineColor('goldLight', '#FEF2DE');
defineColor('goldLighter', '#fffbf3');
defineColor('green', '#e2f1d6');
defineColor('transparent', 'transparent');
insertGlobal('*', { boxSizing: 'border-box' });
insertGlobal('html, body', {
  minHeight: '100%',
});
insertGlobal('body', {
  margin: 0,
  padding: 0,
  fontFamily: `'adobe-text-pro', serif`,
  fontSize: '18px',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
  color: '#5B5A5F',
});
insertGlobal('h1, h2, p', {
  margin: 0,
  padding: 0,
});
insertGlobal('ul', {
  listStyleType: 'none',
  padding: 0,
});
insertGlobal('li', {
  position: 'relative',
  paddingLeft: '1.5em',
  marginBottom: '1em',
});
insertGlobal('li:before', {
  listStyleType: 'none',
  display: 'block',
  position: 'absolute',
  top: '0.3em',
  left: '0',
  content: '🌺',
  fontSize: '.75em',
});

const Outer = ({ children }) => (
  <div className={y('f4 f3_ns lhm ts')}>
    <div
      style={{
        maxWidth: '60em',
        margin: '0 auto',
        boxShadow: '0 2px 10em rgba(0,0,0, 0.2)',
      }}
    >
      {children}
    </div>
  </div>
);

const Flower = ({ name, src, yons }) => (
  <img alt="" className={y(`${yons} pf`)} src={src} />
);

const Primary = ({ children }) => (
  <div
    className={y('w100 h100 pr bw2')}
    style={{
      height: '90vh',
      minHeight: '720px',
      maxHeight: '60em',
      maxWidth: '100%',
      overflow: 'hidden',
    }}
  >
    <Flower yons="bottom0 right0" src={br} />
    <Flower yons="bottom0 left0" src={bl} />
    <Flower yons="top0 left0" src={tl} />
    <Flower yons="top0 right0" src={tr} />
    <Flower yons="top0 left0" src={tl_flower} />
    <Flower yons="bottom0 right0" src={br_flower} />
    <div className={y('flex jc-center ai-center w100 h100 paxs pf mw3')}>
      <div
        className={y('paxs pr')}
        style={{
          backgroundImage:
            'linear-gradient(90deg, #BA6F09 0%, #DEA848 15%, #FADB78 37%, #FFE783 51%, #FEDE77 67%, #FCCA5C 85%, #F6B741 100%)',
        }}
      >
        <div className={y('w100 bg_white pal h100 flex jc-center ai-center')}>
          <div className={y('ptm')}>{children}</div>
        </div>
      </div>
    </div>
  </div>
);

const Secondary = ({ children }) => (
  <div className={y('w100 h100 pr bw2 bg_goldLight')}>
    <div className={y('mw2 mra mla pal')}>{children}</div>
  </div>
);

const Third = ({ children }) => (
  <div className={y('w100 h100 pr bw2 bg_goldLighter')}>
    <div className={y('mw2 mra mla pal')}>{children}</div>
  </div>
);

const compMap = {
  h1: props => (
    <h1 {...props} className={y('f2 f1_ns mtxl cursive mbl alignc')} />
  ),
  h2: props => (
    <h1
      {...props}
      className={y('ttu tracked_loose f3 mtxl mbm fg_black50 alignc')}
    />
  ),
  p: props => <p {...props} className={y('lhm mbm')} />,
  a: props => <a {...props} className={y('fg_lightRed')} />,
};

const IndexPage = () => (
  <Outer>
    <Helmet title="Naomi Jenkins &amp; Jamie Plumer’s Wedding">
      <link rel="stylesheet" href="https://use.typekit.net/jtx4oeb.css" />
    </Helmet>
    <Primary>
      <h1 className={y('cursive alignc b f2 f1_ns lhm')}>
        Naomi Jenkins
        <div className={y('f3 mtm')}>&amp;</div>
        Jamie Plumer
      </h1>
      <h2 className={y('alignc mtl f3 n')}>
        are getting married
        <div className={y('cursive b f2 mts mbs lhs')}>on</div>
        Saturday 27th July 2019
        <div className={y('cursive b f2 mts mbs lhs')}>at</div>
        one o’clock in the afternoon
        <div className={y('mbs')} />
        Gileston Manor, St Athan, South Wales
      </h2>
    </Primary>
    <div className={y('pr z3 bs_soft bg_nearWhite pas')}>
      <div className={y('ptl pbs prm plm f4 bg_white mw1 m0 mla mra')}>
        <Intro
          components={{
            p: props => <p {...props} className={y('mbm')} />,
            blockquote: props => (
              <blockquote {...props} className={y('p0 m0 mbm')} />
            ),
            a: props => <a {...props} className={y('fg_black50')} />,
          }}
        />
      </div>
    </div>
    <Secondary>
      <Accom components={compMap} />
      <Taxis components={compMap} />
    </Secondary>
    <Third>
      <h1 className={y('f2 f1_ns mtxl cursive mbl alignc')}>Bridal Party</h1>
      <Bridesmaids components={compMap} />
    </Third>
    <Secondary>
      <Groomsmen components={compMap} />
    </Secondary>
    <Third>
      <div className={y('paxl')}>
        <a
          className={y('fg_goldDark alignc')}
          href="https://www.johnlewisgiftlist.com/giftint/JSPs/GiftList/BuyGifts/GuestFindAList.jsp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h1 className={y('cursive b f2 f1_ns lhm')}>Gift List &rarr;</h1>
          <span className={y('db mts')}>
            John Lewis, &#8470;<strong>762262</strong>
          </span>
        </a>
        <p className={y('mtxl mbs')}>
          Your wedding presence is more important to us than your wedding
          presents.
        </p>
        <p>
          However — if you do wish to buy us a gift — we have a small gift list
          at John Lewis that opens on <strong>15 June 2019</strong>. Otherwise
          we would love a contribution to our honeymoon fund!
        </p>
      </div>
    </Third>
    <footer className={y('bg_darkGray fg_silver z5 pr alignc pam f5 ts0')}>
      &copy; Naomi &amp; Jamie Plumer-Jenkins 2019
      <div className={y('f6 mtm')}>Made with ❤ in LDN</div>
    </footer>
  </Outer>
);

export default IndexPage;
