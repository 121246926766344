import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="h1" components={components}>{`Accommodation`}</MDXTag>
      <MDXTag name="p" components={components}>{`We would recommend staying in Gileston/St Athan Village, Cowbridge, Llancarfan, Llantwit Major, or Cardiff City.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Our recommendations are below, but there are also many Airbnbs in the surrounding areas and Cardiff. Please `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "mailto:naomiandjamie2019@gmail.com"
        }}>{`let Naomi or Jamie know`}</MDXTag>{` if you need any further help or suggestions.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Hotels`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.townandcountrycollective.co.uk/west-house-hotel/"
          }}>{`The West House (Llantwit Major)`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.townandcountrycollective.co.uk/thebear"
          }}>{`The Bear Hotel (Cowbridge)`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.ihg.com/holidayinnexpress/hotels/gb/en/cardiff/cdfap/hoteldetail"
          }}>{`Holiday Inn Express (Cardiff Airport)`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.celticinternationalhotel.com/"
          }}>{`Celtic International Hotel (Cardiff Airport)`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.booking.com/hotel/gb/three-horseshoes-inn-nr-barry.en-gb.html?aid=389182;label=metagha-link-mapresultsGB-hotel-1670072_dev-desktop_los-1_bw-2_dow-Saturday_defdate-1_room-0_lang-en_curr-GBP_gstadt-2_rateid-0_aud-0_cid-68;sid=1ab83b30fe3cdb6d5f95f18692cb19a9;atlas_src=sr_iw_btn;checkin=2019-07-26;checkout=2019-07-28;dist=0;group_adults=2;nflt=concise_unit_type%3D0%3B;room1=A%2CA;sb_price_type=total;type=total;ucfs=1&"
          }}>{`Three Horseshoes (Barry)`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.fandhllancarfan.co.uk"
          }}>{`The Fox and Hounds (Llancarfan)`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="h2" components={components}>{`B&Bs`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.booking.com/hotel/gb/tipiretreats.en-us.html?aid=809250&sid=1ab83b30fe3cdb6d5f95f18692cb19a9&srpvid=218a710dc6cf0040&ucfs=1&srpvid=218a710dc6cf0040&srepoch=1551974683&hpos=1&hapos=1&dest_id=-2596305&dest_type=city&sr_order=popularity&from=searchresults;highlight_room=#hotelTmpl"
          }}>{`Tipi Retreats`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.facebook.com/rosecottagegileston"
          }}>{`Rose Cottage – tel no. 07885 416 103`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://www.thehayloft.info"
          }}>{`The Hay Loft – tel no. 01446 750 687`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://www.pebbles-gilestoncottage.co.uk"
          }}>{`Pebbles Cottage – tel no. 07817 672 509`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.booking.com/hotel/gb/clemenstone-house.en-gb.html?aid=389182;label=metagha-link-mapresultsGB-hotel-1670072_dev-desktop_los-1_bw-2_dow-Saturday_defdate-1_room-0_lang-en_curr-GBP_gstadt-2_rateid-0_aud-0_cid-68;sid=1ab83b30fe3cdb6d5f95f18692cb19a9;all_sr_blocks=47486903_94472836_0_1_0;checkin=2019-07-26;checkout=2019-07-28;dest_id=-2593398;dest_type=city;dist=0;group_adults=2;hapos=3;highlighted_blocks=47486903_94472836_0_1_0;hpos=3;map=1;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1551975428;srpvid=cda77282a5170153;type=total;ucfs=1&#map_closed"
          }}>{`Clemenstone House B&B`}</MDXTag></MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      