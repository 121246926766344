import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import { Avatar } from '../components/avatar';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="h2" components={components}>{`Bridesmaids`}</MDXTag>
      <Avatar name="Heini Cleaver" src="Heini" />
      <MDXTag name="h3" components={components}>{`How do you know Naomi?`}</MDXTag>
      <MDXTag name="p" components={components}>{`We ignored each other for five years in secondary school, and the first time we spoke was when Naomi offered me a minstrel in our a-level law class. I don’t share chocolate, so thought she was either strange or generous. Turns out it was the latter.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We became inseparable after the first night of university when we both rushed to the dance floor as “Hips Don’t Lie” by Shakira came on. Nearly 13 years later and here we are….`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Fondest memory`}</MDXTag>
      <MDXTag name="p" components={components}>{`Climbing a volcano in Indonesia which our fellow climbers found a breeze, whilst we wheezed, nearly fainted and hobbled our way to the top.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`One word to describe Naomi`}</MDXTag>
      <MDXTag name="p" components={components}>{`LOUD.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Kidding.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Bestfriendanyonecouldeveraskfor. Does that count?`}</MDXTag>
      <Avatar name="Hannah Jenkins" src="Hannah" />
      <MDXTag name="h3" components={components}>{`How do you know Naomi?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Sister`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Fondest memory`}</MDXTag>
      <MDXTag name="p" components={components}>{`One of my favourite memories of Naomi was when the family went on mass to London for the Classical Brits when we were in our late teens. Naomi took perhaps too much advantage of the free bar there and ended up telling Lang-Lang (world famous concert pianist) that he was “good at the piano”.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`One word to describe Naomi`}</MDXTag>
      <MDXTag name="p" components={components}>{`Loud`}</MDXTag>
      <Avatar name="Ruth Jenkins" src="Ruth" />
      <MDXTag name="h3" components={components}>{`How do you know Naomi?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Sister`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Fondest memory`}</MDXTag>
      <MDXTag name="p" components={components}>{`I don't think it was a particularly enjoyable experience for her, but probably one of the most amusing memories I have of Naomi is when she tried to get onto a bank (which was about a meter away) whilst in a fast-moving boat, white water rafting in France. Amazingly the wobbly leap of faith worked, and she landed on her feet, home and dry.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`One word to describe Naomi`}</MDXTag>
      <MDXTag name="p" components={components}>{`Big-hearted`}</MDXTag>
      <Avatar name="## Alexandra Machin (Plumer)" src="Alexandra" />
      <MDXTag name="h3" components={components}>{`How do you know Naomi?`}</MDXTag>
      <MDXTag name="p" components={components}>{`I’m Alex; sister of the groom and bridesmaid. First met her at Jamie’s house party in Earlsfield, then drove her home when I realised she was my next-door neighbour.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`One word to describe Naomi`}</MDXTag>
      <MDXTag name="p" components={components}>{`Mad (for marrying my brother)`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      