import React from 'react';
import { yon as y } from 'yons';

import Alexandra from '../images/Alexandra.jpeg';
import George from '../images/George.jpeg';
import Hannah from '../images/Hannah.jpeg';
import Heini from '../images/Heini.jpeg';
import Patrick from '../images/Patrick.jpg';
import Ruth from '../images/Ruth.jpg';
import Stephen from '../images/Stephen.jpeg';
import Thomas from '../images/Thomas.jpeg';
import Jonny from '../images/Jonny.jpg';
import Richard from '../images/Richard.jpg';
import Alex from '../images/Alex.jpg';

const getRandomArbitrary = (min, max) => Math.random() * (max - min) + min;
const getAngle = () => getRandomArbitrary(-3, 3);

const images = {
  Alexandra,
  George,
  Hannah,
  Heini,
  Patrick,
  Ruth,
  Stephen,
  Thomas,
  Jonny,
  Richard,
  Alex,
};

export const Avatar = ({ name, src }) => (
  <div className={y('flex jc-center mtxl')}>
    <div
      className={y('mtxl mbm mla mra')}
      style={{
        transform: `rotate(${getAngle()}deg)`,
      }}
    >
      <div
        style={{
          background: 'white',
        }}
        className={y('bs_soft dib pal')}
      >
        <div
          className={y('w5 h5')}
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${images[src]})`,
          }}
        />
        <h3 className={y('marker f4 n alignc m0 ptm')}>{src}</h3>
      </div>
    </div>
  </div>
);
