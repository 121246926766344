import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Hello! If you’re here then you’re probably invited to our wedding.`}</MDXTag>
        <MDXTag name="p" components={components} parentName="blockquote">{`Please find all the details below, and email `}<MDXTag name="a" components={components} parentName="p" props={{
            "href": "mailto:naomiandjamie2019@gmail.com"
          }}>{`naomiandjamie2019@gmail.com`}</MDXTag>{` if you have any questions!`}</MDXTag>
        <MDXTag name="p" components={components} parentName="blockquote">{`We can’t wait to see you at the wedding xx`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`— Naomi `}{`&`}{` Jamie`}</MDXTag></MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      