import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="h1" components={components}>{`Taxis`}</MDXTag>
      <MDXTag name="p" components={components}>{`Please note that whilst Uber does operate out of Cardiff city you are likely to struggle to get one to pick you up from the venue at the end of the night. We recommend booking a taxi beforehand.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The venue has stated that buses are not able to reach the venue due to lack of turning space, but minibuses are fine. Cars can be left at Gileston Manor overnight but that they must be picked up by 11am the next day.`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "mailto:info@abcoaches.co.uk"
          }}>{`A & B Coaches`}</MDXTag>{` 029 2059 5600`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Bay Cars (Llantwit Major) 02920 350 350`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`J D Cars (Barry) 01446 711 460`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Airport Karz (Llantwit Major) 01446 793 995`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Cardiff and Vale Taxis 07763 605 385`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Dragon Taxis (Cardiff) 02920 333 333`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Premier Cars Ltd (Cardiff) 02920 555 555`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Capital Cabs (Cardiff) 02920 777 777`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://minibushireincardiff.co.uk/"
          }}>{`JJ's Minibus Hire Cardiff`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://www.wheadons-group.co.uk/"
          }}>{`Weadon's Minibuses`}</MDXTag></MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      