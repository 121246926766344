import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import { Avatar } from '../components/avatar';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="h2" components={components}>{`Groomsmen`}</MDXTag>
      <Avatar name="George Keating" src="George" />
      <MDXTag name="h3" components={components}>{`How do you know Jamie?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Much of getting to know Jamie at school was by being on the receiving end of his asinine practical jokes. I'd say his finest work was when he used his bikelock to chain my school bag to a drainpipe in a busy student thoroughfare; simple yet effective.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Fondest memory`}</MDXTag>
      <MDXTag name="p" components={components}>{`In 2005 a few of us ventured down to Newquay to indulge in underage drinking and enjoy Cornwall's overcrowded beaches. That summer is famous for England's historic victory against Australia in the Ashes. I have particularly fond memories of Jamie participating in a game of beach cricket with such zeal that he pioneered his own brand of cricket shot (or, I suppose, non-shot). Where a delivery was wide of the stumps, he would lunge as far as he possibly could to play a shot only to then purposefully leave it, wave his bat in the air, and shout "Andrew Strauss!" at the top of his voice. It was quite a sight.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`One word to describe Jamie`}</MDXTag>
      <MDXTag name="p" components={components}>{`Unfazed.`}</MDXTag>
      <Avatar name="Thomas Plumer" src="Thomas" />
      <MDXTag name="h3" components={components}>{`How do you know Jamie?`}</MDXTag>
      <MDXTag name="p" components={components}>{`I've known Jamie for the best part of 30 years, albeit not by choice. I first met him when I moved into my bedroom in Pinkneys Green in 1988, and he has hung around ever since.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Fondest memory`}</MDXTag>
      <MDXTag name="p" components={components}>{`It has to be playing in the SW Borlase 1st XV with Jamie. After years of brotherly competition, this was also when I came to terms with the fact that Jamie is a lot better than me at rugby (although I still won't admit this to him).`}</MDXTag>
      <MDXTag name="h3" components={components}>{`One word to describe Jamie`}</MDXTag>
      <MDXTag name="p" components={components}>{`Honest.`}</MDXTag>
      <Avatar name="Patrick Scanlon" src="Patrick" />
      <MDXTag name="h3" components={components}>{`How do you know Jamie?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Jamie and I played junior rugby together and then were in the same form at secondary school.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Fondest memory`}</MDXTag>
      <MDXTag name="p" components={components}>{`During the year between school and uni, Jamie won 2 free tickets to go round Europe and offered one to me. Despite a major argument over a card game (my bad), an incident with some Alsatians, and one trip to hospital, we had a great time!`}</MDXTag>
      <MDXTag name="h3" components={components}>{`One word to describe Jamie`}</MDXTag>
      <MDXTag name="p" components={components}>{`Unique.`}</MDXTag>
      <Avatar name="Alexander Conway" src="Alex" />
      <MDXTag name="h3" components={components}>{`How do you know Jamie?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Durham University, St Cuthbert's Society.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Fondest memory`}</MDXTag>
      <MDXTag name="p" components={components}>{`During a rugby social when Jamie projectile vomited on a fellow rugby teammates’ suede shoes which started a procession of projectile vomiting from other teammates.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`One word to describe Jamie`}</MDXTag>
      <MDXTag name="p" components={components}>{`Made-of-glass.`}</MDXTag>
      <Avatar name="Richard Skinner" src="Richard" />
      <MDXTag name="h3" components={components}>{`How do you know Jamie?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Cuths Bs rugby team.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Fondest memory`}</MDXTag>
      <MDXTag name="p" components={components}>{`Drinking a beer on the beach in Byron Bay watching the whales swim past as the sun set after a day surfing.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`One word to describe Jamie`}</MDXTag>
      <MDXTag name="p" components={components}>{`Forgetful (or if we want postive things then loyal)`}</MDXTag>
      <Avatar name="Stephen Gunnell" src="Stephen" />
      <MDXTag name="h3" components={components}>{`How do you know Jamie?`}</MDXTag>
      <MDXTag name="p" components={components}>{`I met Jamie at secondary school, where he tripped me up outside the school hall and I grazed my knees. Some profuse bleeding and strapping up by the school matron later Jamie was very apologetic about the situation. We’ve been friends ever since.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Fondest memory`}</MDXTag>
      <MDXTag name="p" components={components}>{`Surfing the same waves in Spain and Bali or fistbumping a monkey on a volcano in Indonesia.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`One word to describe Jamie`}</MDXTag>
      <MDXTag name="p" components={components}>{`Young-at-heart. Is that one word?`}</MDXTag>
      <Avatar name="Jonny Hargreaves" src="Jonny" />
      <MDXTag name="h3" components={components}>{`How do you know Jamie?`}</MDXTag>
      <MDXTag name="p" components={components}>{`University toga party 2006; really didn't like him for the first three to six months; way too loud, and potentially better at rugby than me.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Fondest memory`}</MDXTag>
      <MDXTag name="p" components={components}>{`Spending six rainless days walking through Scotland sleeping in bothies and climbing Munros, pretending we were characters in Lord of the Rings.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`One word to describe Jamie`}</MDXTag>
      <MDXTag name="p" components={components}>{`Pain-in-the-arse`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      